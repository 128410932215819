import {FunctionComponent, PropsWithChildren} from "react";

interface LoadingProps extends PropsWithChildren {
    isLoading: boolean,
}

const Loading: FunctionComponent<LoadingProps> = ({ isLoading, children }) => {
    return (
        <>
            { isLoading ? <p>loading</p> : children }
        </>
    );
}

export default Loading;
