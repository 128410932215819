import {FunctionComponent} from "react";
import {useFormContext, get} from "react-hook-form";

interface InlineFormErrorProps {
    name: string,
    messages?: {[type: string]: string}
}

const InlineFormError: FunctionComponent<InlineFormErrorProps> = ({ name, messages = {} }) => {
    const form = useFormContext();
    const error = get(form.formState.errors, name);

    if (!error) return null;
    const message = messages[error.type] || error.message;
    return <div className="c-validation" aria-hidden="true">{message}</div>;
};

export const WithErrorBorder = (name: string, classNames: string): string => {
    const form = useFormContext();
    const error = get(form.formState.errors, name);
    return `${classNames} ${error ? 'error-border' : ''}`;
};

export default InlineFormError;