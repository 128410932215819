import ForPriceForm from "../components/ForPriceForm/ForPriceForm";
import PriceSelector from "../components/PriceSelector";
import {FunctionComponent, useState} from "react";

const Main: FunctionComponent = () => {
    const [canProceed, setCanProceed] = useState(false);

    return (
        <>
            <h1>Ihr Heizölpreis</h1>
            <div className='row justify-content-between'>
                <div className='col-4'>
                    <ForPriceForm onValidityChange={setCanProceed}/>
                </div>
                <div className='col-7'>
                    <PriceSelector pricesActive={canProceed}/>
                </div>
            </div>
        </>
    )
};

export default Main;