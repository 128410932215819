import {FunctionComponent, useEffect} from "react";
import {useOutletContext} from "react-router-dom";
import {CheckoutContainerContext} from "../layouts/CheckoutContainer";

const DesiredPriceSucceeded: FunctionComponent = () => {
    const { setTitle, setSubtitle } = useOutletContext<CheckoutContainerContext>();

    useEffect(() => {
        setTitle('Wir haben Ihren Wunschpreis erhalten!');
        setSubtitle(' ');
    }, []);

    return (
        <>
            <p>Vielen Dank für Ihr Vertrauen in uns. Sie erhalten von uns eine E-Mail, sobald Ihr Wunschpreis erreicht ist, oder unterschritten wurde.</p>
        </>
    );
};

export default DesiredPriceSucceeded;