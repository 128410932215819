import {FunctionComponent, useEffect} from "react";
import {Controller, useFormContext} from "react-hook-form";
import {CheckoutFormData, CheckoutFormSalutation} from "./CheckoutForm";
import translate, {TranslationKey} from "../../utils/translation";
import InlineFormError from "../InlineFormError";

interface DeliveryAddressCheckoutFormPartProps {
    index: number,
    lastIndex: number,
    overallDeliveryQuantityInLinter: number,
    zipcode?: string,
    correctOverallDeliveryQuantityInLiter: () => boolean,
}

const DeliveryAddressCheckoutFormPart: FunctionComponent<DeliveryAddressCheckoutFormPartProps> = (props) => {
    const { control, register, setValue, getValues, formState: { errors }, watch } = useFormContext<CheckoutFormData>();

    const salutationWatcher = watch(`deliveryAddresses.${props.index}.salutation`);

    useEffect(() => {
        setValue(`deliveryAddresses.${props.index}.salutation`, CheckoutFormSalutation.Herr);
        if (isAlone) setValue(`deliveryAddresses.${props.index}.deliveryQuantityInLiter`, props.overallDeliveryQuantityInLinter);
    }, []);

    useEffect(() => {
        if (props.zipcode) setValue(`deliveryAddresses.${props.index}.zipcode`, props.zipcode);
    }, [props.zipcode]);

    const isFirst: boolean = props.index === 0;
    const isLast: boolean = props.lastIndex === 0;
    const isAlone: boolean = isFirst && isLast;

    return (
        <>
            <h4 className='mt-2'>Lieferadresse {!isFirst ? props.index + 1 : ''}</h4>
            <div className='row'>
                <div className='col-2'>
                    <label>Anrede*</label>
                    <select {...register(`deliveryAddresses.${props.index}.salutation`, { required: { value: true, message: translate(TranslationKey.RequiredFieldError) } })} className='form-select'>
                        {
                            Object.entries(CheckoutFormSalutation).map(([key, value]) => (
                                <option key={key}>{value}</option>
                            ))
                        }
                    </select>
                    <InlineFormError name={`deliveryAddresses.${props.index}.salutation`}/>
                </div>

                <div className='col-5'>
                    <label>Vorname*</label>
                    <input {...register(`deliveryAddresses.${props.index}.firstName`, { required: { value: true, message: translate(TranslationKey.RequiredFieldError) } })} type='text' className='form-control'/>
                    <InlineFormError name={`deliveryAddresses.${props.index}.firstName`}/>
                </div>

                <div className='col-5'>
                    <label>Name*</label>
                    <input {...register(`deliveryAddresses.${props.index}.lastName`, { required: { value: true, message: translate(TranslationKey.RequiredFieldError) } })} type='text' className='form-control'/>
                    <InlineFormError name={`deliveryAddresses.${props.index}.lastName`}/>
                </div>

                {
                    salutationWatcher === CheckoutFormSalutation.Firma && (
                        <div className='row'>
                            <div className='col'>
                                <label>Firma*</label>
                                <input {...register(`deliveryAddresses.${props.index}.company`, { required: { value: true, message: translate(TranslationKey.RequiredFieldError) } })} type='text' className='form-control'/>
                                <InlineFormError name={`deliveryAddresses.${props.index}.company`}/>
                            </div>
                        </div>
                    )
                }
            </div>

            {
                !isFirst && (
                    <div className='row'>
                        <div className='col-2'>
                            <label>Vorwahl</label>
                            <input {...register(`deliveryAddresses.${props.index}.phonePrefix`)} type='text' className='form-control'/>
                            <InlineFormError name={`deliveryAddresses.${props.index}.phonePrefix`}/>
                        </div>
                        <div className='col text-center'><p className='mt-2'>/</p></div>
                        <div className='col-9'>
                            <label>Telefon</label>
                            <input {...register(`deliveryAddresses.${props.index}.phoneNumber`)} type='text' className='form-control'/>
                            <InlineFormError name={`deliveryAddresses.${props.index}.phoneNumber`}/>
                        </div>
                    </div>
                )
            }

            <div className={'row'}>
                <div className={'col-8'}>
                    <label>Straße*</label>
                    <input {...register(`deliveryAddresses.${props.index}.street`, { required: { value: true, message: translate(TranslationKey.RequiredFieldError) } })} type='text' className='form-control'/>
                    <InlineFormError name={`deliveryAddresses.${props.index}.street`}/>
                </div>
                <div className={'col-4'}>
                    <label>Hausnummer*</label>
                    <input {...register(`deliveryAddresses.${props.index}.housenumber`, { required: { value: true, message: translate(TranslationKey.RequiredFieldError) } })} type='text' className='form-control'/>
                    <InlineFormError name={`deliveryAddresses.${props.index}.housenumber`}/>
                </div>
            </div>

            <div className={'row'}>
                <div className={'col-4'}>
                    <label>Postleitzahl*</label>
                    <input {...register(`deliveryAddresses.${props.index}.zipcode`, { required: { value: true, message: translate(TranslationKey.RequiredFieldError) } })} type='text' className='form-control' disabled={true}/>
                    <InlineFormError name={`deliveryAddresses.${props.index}.zipcode`}/>
                </div>
                <div className={'col-8'}>
                    <label>Ort*</label>
                    <input {...register(`deliveryAddresses.${props.index}.city`, { required: { value: true, message: translate(TranslationKey.RequiredFieldError) } })} type='text' className='form-control'/>
                    <InlineFormError name={`deliveryAddresses.${props.index}.city`}/>
                </div>
            </div>

            <div className={'row'}>
                <div className={'col-md-12'}>
                    <label>Ortsteil</label>
                    <input {...register(`deliveryAddresses.${props.index}.district`)} type='text' className='form-control'/>
                </div>
            </div>

            <div className={'row'}>
                <div className={'col-5'}>
                    <label>{translate(TranslationKey.DeliveryQuantity)}*</label>
                    <div className='input-group'>
                        <input {...register(`deliveryAddresses.${props.index}.deliveryQuantityInLiter`, { required: { value: true, message: translate(TranslationKey.RequiredFieldError) }, validate: { 'invalid-overall': props.correctOverallDeliveryQuantityInLiter } })} type='number' className={'form-control'} disabled={isAlone}/>
                        <span className="input-group-text">Liter</span>
                    </div>
                    <InlineFormError name={`deliveryAddresses.${props.index}.deliveryQuantityInLiter`} messages={{ 'invalid-overall': translate(TranslationKey.InvalidOverall) }}/>
                </div>
            </div>

            <label>Ist Lieferstelle vermietet / teilvermietet?*</label>
            <div className='form-check'>
                <Controller
                    name={`deliveryAddresses.${props.index}.rented`}
                    control={control}
                    rules={{ validate: { 'required': (value) => typeof value === 'boolean' }}}
                    render={({field}) => (
                        <input {...field}
                               id='rented-yes'
                               className='form-check-input'
                               type='radio'
                               value='yes'
                               onChange={(event) => setValue(field.name, event.target.value === 'yes')}
                        />
                    )}
                />
                <label className='form-check-label' htmlFor='rented-no'>Ja</label>
            </div>
            <div className='form-check'>
                <Controller
                    name={`deliveryAddresses.${props.index}.rented`}
                    control={control}
                    rules={{ validate: { 'required': (value) => typeof value === 'boolean' }}}
                    render={({field}) => (
                        <input {...field}
                               id='rented-no'
                               className='form-check-input'
                               type='radio'
                               value='no'
                               onChange={(event) => setValue(field.name, event.target.value === 'yes')}
                        />
                    )}
                />
                <label className='form-check-label' htmlFor='rented-no'>Nein</label>
            </div>
            <InlineFormError name={`deliveryAddresses.${props.index}.rented`} messages={{ 'required': translate(TranslationKey.RequiredFieldError) }}/>
            <p className='mt-1 small'>Erforderliche Angabe zur Ermittlung der CO2 Anlage nach dem Kostenaufstellungsgesetz</p>
        </>
    );
};

export default DeliveryAddressCheckoutFormPart;