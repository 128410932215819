enum Language {
    DE = 'de-DE',
}

export enum TranslationKey {
    Zipcode,
    DeliveryPoint,
    DeliveryQuantity,
    DeliveryPeriod,
    DeliveryTime,
    PaymentMethod,
    FuelTruck,
    Hose,
    InvalidZipcodeError,
    DeliveryQuantityOutOfBounceError,
    RequiredFieldError,
    InvalidOverall,
    ZipcodeNotFound
}

type Translation = {
    [key in TranslationKey]: string;
};

type Translations = {[language in Language]: Translation};

const translations: Translations = {
    [Language.DE]: {
        [TranslationKey.Zipcode]: 'Postleitzahl',
        [TranslationKey.DeliveryPoint]: 'Lieferstellen',
        [TranslationKey.DeliveryQuantity]: 'Liefermenge',
        [TranslationKey.DeliveryPeriod]: 'Lieferfrist',
        [TranslationKey.DeliveryTime]: 'Lieferzeit',
        [TranslationKey.PaymentMethod]: 'Zahlungsart',
        [TranslationKey.FuelTruck]: 'Tankwagen',
        [TranslationKey.Hose]: 'Schlauch',
        [TranslationKey.InvalidZipcodeError]: 'Die Postleitzahl ist fehlerhaft!',
        [TranslationKey.DeliveryQuantityOutOfBounceError]: 'Die Mindestbestellmenge beträgt 500 Liter. Die Maximalbestellmenge beträgt 32000 Liter.',
        [TranslationKey.RequiredFieldError]: 'Dieses Feld muss ausgefüllt sein!',
        [TranslationKey.InvalidOverall]: 'Die Bestellmenge ist nicht konsistent!',
        [TranslationKey.ZipcodeNotFound]: 'Diese Postleitzahl wurde nicht gefunden!',
    },
}

const resolveTranslation = (translations: Translations, key: TranslationKey, language: Language): string => {
    const valueSet = translations[language];
    if (!valueSet) return TranslationKey[key];
    const value = valueSet[key];
    return value || TranslationKey[key];
}

const translate = (key: TranslationKey) => {
    return resolveTranslation(translations, key, Language.DE);
}

export default translate;