import {FunctionComponent, useEffect} from "react";
import {useNavigate, useOutletContext} from "react-router-dom";
import {CheckoutContainerContext} from "../layouts/CheckoutContainer";
import {FormProvider, useForm} from "react-hook-form";
import InlineFormError from "../components/InlineFormError";
import translate, {TranslationKey} from "../utils/translation";
import {getPriceDataFromStoreData, useStore} from "../hooks/useStore";
import ExternalEvents from "../utils/ExternalEvents";
import useConfig from "../hooks/useConfig";

export interface DesiredPriceFormData {
    desiredPriceNetto: number,
    desiredPriceBrutto: number,
    email: string,
    notificationPeriod: string,
}

const DesiredPrice: FunctionComponent = () => {
    const { setTitle, setSubtitle } = useOutletContext<CheckoutContainerContext>();
    const formMethods = useForm<DesiredPriceFormData>();
    const { register, handleSubmit, watch, setValue } = formMethods;
    const navigate = useNavigate();
    const { state } = useStore();
    const { apiBaseUrl } = useConfig()

    useEffect(() => {
        if (state?.forPriceFormData)
            ExternalEvents.getInstance().triggerDesiredPriceFormOpened({
                ...state.forPriceFormData,
                ...getPriceDataFromStoreData(state),
            });

        setTitle('Wunschpreis')
        setSubtitle('Sie haben hier die Möglichkeit, einen Wunschpreis einzugeben, bei dessen Erreichen Sie benachrichtigt werden.');
        setValue('notificationPeriod', '4 Wochen');
    }, []);

    useEffect(() => {
        const subscription = watch((value, { name, type }) => {
            if (name === 'desiredPriceNetto' && type === 'change' && value.desiredPriceNetto !== undefined) {
                setValue('desiredPriceBrutto', parseFloat((value.desiredPriceNetto * 1.19).toFixed(2)));
            } else if (name === 'desiredPriceBrutto' && type === 'change' && value.desiredPriceBrutto !== undefined) {
                setValue('desiredPriceNetto', parseFloat((value.desiredPriceBrutto / 1.19).toFixed(2)));
            }
        });
        return () => subscription.unsubscribe();
    }, [watch()]);

    const onSubmit = (formData: DesiredPriceFormData) => {
        if (!state.forPriceFormData) return;

        const body = {
            emailAddress: formData.email,
            netPrice: formData.desiredPriceNetto,
            bruttoPrice: formData.desiredPriceBrutto,
            notificationPeriod: formData.notificationPeriod,

            isPremium: state.product === 'premium',
            zipcode: state.forPriceFormData.zipcode,
            deliveryStation: state.forPriceFormData.amountOfDeliveryPoints,
            deliveryQuantity: state.forPriceFormData.overallDeliveryQuantityInLiter,
            deliveryPeriod: state.forPriceFormData.deliveryPeriod,
            deliveryTime: state.forPriceFormData.deliveryTime,
            deliveryMethod: state.forPriceFormData.paymentMethod,
            truck: state.forPriceFormData.fuelTruckType,
            tube: state.forPriceFormData.hoseType,

            basePriceNetto: state.basePriceNetto,
            basePriceBrutto: state.basePriceBrutto,
            totalPriceNetto: state.fullPriceBrutto / 1.19,
            totalPriceBrutto: state.fullPriceBrutto
        };

        fetch(`${apiBaseUrl}/senddesiredpricerequest`, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(body),
        })
            .then((res) => {
                if (res.status !== 200) throw new Error(`Invalid status code, expected 200 got ${res.status}`);
                return res.json();
            })
            .then((data: { success: boolean }) => {
                if (!data.success) throw new Error(`Invalid response ${JSON.stringify(data)}`);
                ExternalEvents.getInstance().triggerDesiredPriceRequested(formData);
                navigate('/desired-price-succeeded');
            })
            .catch(console.error);
    };

    return (
        <>
            <button className={'button'} onClick={() => navigate(-1)}>Zurück</button>
            <FormProvider {...formMethods}>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <label>Ihr Wunschpreis</label>

                    <div className='row align-items-center'>
                        <div className='col-4'>
                            <input {...register('desiredPriceNetto', { required: { value: true, message: translate(TranslationKey.RequiredFieldError) },  })} type='number' step={'.01'} className={'form-control mb-0'}/>
                        </div>
                        <div className='col-8'>
                            <p className='mb-0'>€ pro 100 Liter (zzgl. 19% MwSt)</p>
                        </div>
                        <InlineFormError name='desiredPriceNetto'/>
                    </div>

                    <div className="row align-items-center mt-1">
                        <div className="col-4">
                            <input {...register('desiredPriceBrutto', { required: { value: true, message: translate(TranslationKey.RequiredFieldError) } })} type='number' step={'.01'} className={'form-control mb-0'}/>
                        </div>
                        <div className="col-8">
                            <p className="mb-0">€ pro 100 Liter (inkl. 19% MwSt)</p>
                        </div>
                        <InlineFormError name={'desiredPriceBrutto'}/>
                    </div>

                    <p className='mt-2'>
                        Sollte mein eingegebener Wunschpreis innerhalb der nächsten
                        <select {...register('notificationPeriod')} className='form-select small ms-2 me-2'>
                            <option value='8 Wochen'>8 Wochen</option>
                            <option value='4 Wochen'>4 Wochen</option>
                            <option value='2 Wochen'>2 Wochen</option>
                            <option value='7 Tage'>7 Tage</option>
                            <option value='3 Tage'>3 Tage</option>
                        </select>
                        nicht erreicht oder unterschritten werden, senden Sie mir bitte eine
                        Erinnerungsmail an folgende E-Mail-Adresse:
                    </p>

                    <label>E-Mail Adresse</label>
                    <input {...register('email', { required: { value: true, message: translate(TranslationKey.RequiredFieldError) } })} type='email' className='form-control' />
                    <InlineFormError name='email'/>

                    <button type="submit" className="button"><strong>Wunschpreis anmelden</strong>
                        <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" fill="currentColor" className="bi bi-arrow-right-square-fill" viewBox="0 0 16 16">
                            <path
                                d="M0 14a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2a2 2 0 0 0-2 2v12zm4.5-6.5h5.793L8.146 5.354a.5.5 0 1 1 .708-.708l3 3a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708-.708L10.293 8.5H4.5a.5.5 0 0 1 0-1z"/>
                        </svg>
                    </button>
                </form>
            </FormProvider>
        </>
    )
};

export default DesiredPrice;