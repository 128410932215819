import {FunctionComponent, useEffect} from "react";
import {getPriceDataFromStoreData, useStore} from "../../hooks/useStore";
import {FormProvider, useForm} from "react-hook-form";
import PersonalCheckoutFormPart from "./PersonalCheckoutFormPart";
import DeliveryAddressCheckoutFormPart from "./DeliveryAddressCheckoutFormPart";
import InvoiceAddressCheckoutFormPart from "./InvoiceAddressCheckoutFormPart";
import DeliveryOptionsCheckoutFormPart from "./DeliveryOptionsCheckoutFormPart";
import {useNavigate} from "react-router-dom";
import ExternalEvents from "../../utils/ExternalEvents";

export interface CheckoutFormPersonalData {
    email: string,
    phonePrefix: string,
    phoneNumber: string,
    dateOfBirth?: Date
}

export enum CheckoutFormSalutation {
    Herr = 'Herr',
    Frau = 'Frau',
    Divers = 'Divers',
    Firma = 'Firma',
}

interface CheckoutFormAddressData {
    salutation: CheckoutFormSalutation,
    firstName: string,
    lastName: string,
    street: string,
    housenumber: string,
    zipcode: string,
    city: string,
    district: string,
    company?: string,
    phonePrefix?: string,
    phoneNumber?: string,
}

export interface CheckoutFormDeliveryAddressData extends CheckoutFormAddressData {
    deliveryQuantityInLiter: number,
    rented: boolean
}

export interface CheckoutFormInvoiceAddressData extends CheckoutFormAddressData {}

export interface CheckoutFormDeliveryOptionsData {
    paymentMethod: string,
    deliveryPeriod: string,
    comment: string,
    customerNumber: string,
    termsAccepted: boolean,
    iban: string,
    bic: string,
}

export interface CheckoutFormData {
    personal: CheckoutFormPersonalData,
    deliveryAddresses: CheckoutFormDeliveryAddressData[],
    invoiceAddress: CheckoutFormInvoiceAddressData,
    deliveryOptions: CheckoutFormDeliveryOptionsData,
    invoiceAddressSameAsDeliveryAddress: boolean,
}

const CheckoutForm: FunctionComponent = () => {
    const navigate = useNavigate();
    const { state, setState } = useStore();
    const formMethods = useForm<CheckoutFormData>();
    const { register, handleSubmit, setValue, getValues, watch, reset } = formMethods;

    const invoiceAddressSameAsDeliveryAddressWatcher = watch('invoiceAddressSameAsDeliveryAddress');

    useEffect(() => {
        if (state?.forPriceFormData)
            ExternalEvents.getInstance().triggerCheckoutFormOpened({
                ...state.forPriceFormData,
                ...getPriceDataFromStoreData(state),
            });
        setValue('invoiceAddressSameAsDeliveryAddress', true);
        if (state.checkoutFormData) reset(state.checkoutFormData);
    }, []);

    const onSubmit = (data: CheckoutFormData) => {
        setState((state) => ({
            ...state,
            checkoutFormData: data,
        }));

        navigate('/checkout-confirm');
    };

    const correctOverallDeliveryQuantityInLiter = (): boolean => {
        const overallDeliveryQuantityInLiter = getValues('deliveryAddresses').reduce((pV, cV) => pV + parseFloat(cV.deliveryQuantityInLiter as unknown as string), 0);
        return overallDeliveryQuantityInLiter === parseFloat(state.forPriceFormData?.overallDeliveryQuantityInLiter as unknown as string);
    };

    const deliveryAddressCheckoutFormParts = (): JSX.Element[] => {
        const amountOfDeliveryPoints = state.forPriceFormData?.amountOfDeliveryPoints || 1;
        let parts: JSX.Element[] = [];
        for (let i = 0; i < amountOfDeliveryPoints; i++) {
            parts.push(<DeliveryAddressCheckoutFormPart
                key={i}
                index={i}
                lastIndex={amountOfDeliveryPoints - 1}
                overallDeliveryQuantityInLinter={state.forPriceFormData!.overallDeliveryQuantityInLiter}
                zipcode={state.forPriceFormData?.zipcode}
                correctOverallDeliveryQuantityInLiter={correctOverallDeliveryQuantityInLiter}
            />);
        }
        return parts;
    };

    return (
        <FormProvider {...formMethods}>
            <form onSubmit={handleSubmit(onSubmit)}>
                <PersonalCheckoutFormPart/>

                <div className='form-check'>
                    <input {...register('invoiceAddressSameAsDeliveryAddress')} className='form-check-input' type='checkbox' />
                    <label className='form-check-label'>Die Rechnungsadresse ist die gleiche wie die Lieferadresse.</label>
                </div>

                {!invoiceAddressSameAsDeliveryAddressWatcher && <InvoiceAddressCheckoutFormPart zipcode={state.forPriceFormData?.zipcode}/>}

                {deliveryAddressCheckoutFormParts()}

                <DeliveryOptionsCheckoutFormPart/>

                <button type="submit" className="button">
                    <strong>Weiter zur Prüfung</strong>
                    <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22"
                         fill="currentColor"
                         className="bi bi-arrow-right-square-fill"
                         viewBox="0 0 16 16">
                        <path d="M0 14a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2a2 2 0 0 0-2 2v12zm4.5-6.5h5.793L8.146 5.354a.5.5 0 1 1 .708-.708l3 3a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708-.708L10.293 8.5H4.5a.5.5 0 0 1 0-1z"/>
                    </svg>
                </button>

                <p className="mt-2 small">Die mit (*) gekennzeichneten Felder sind für die Bestellung erforderlich.</p>
            </form>
        </FormProvider>
    );
}

export default CheckoutForm;