import {ExternalParams, ForPriceFormData} from "../components/ForPriceForm/ForPriceForm";
import {CheckoutFormData} from "../components/CheckoutForm/CheckoutForm";
import {DesiredPriceFormData} from "../views/DesiredPrice";
import {PriceData} from "../hooks/useStore";

export interface ExternalEvent {
    type: string;
}

export interface HelOpened extends ExternalEvent, Partial<ExternalParams> {
    type: 'helOpened',
}

export interface CheckoutFormOpened extends ExternalEvent, ForPriceFormData, PriceData {
    type: 'checkoutFormOpened',
}

export interface OrderOverviewOpened extends ExternalEvent, CheckoutFormData, PriceData {
    type: 'orderOverviewOpened',
}

export interface CheckoutSucceeded extends ExternalEvent, CheckoutFormData, PriceData {
    type: 'checkoutSucceeded',
    summarizedDeliveryQuantityInLiter: number,
}

export interface DesiredPriceFormOpened extends ExternalEvent, ForPriceFormData, PriceData {
    type: 'desiredPriceFormOpened'
}

export interface DesiredPriceRequested extends ExternalEvent, DesiredPriceFormData {
    type: 'desiredPriceRequested',
}

class ExternalEvents {
    private static instance: ExternalEvents;

    private constructor() {}

    public static getInstance(): ExternalEvents {
        if (!ExternalEvents.instance)
            ExternalEvents.instance = new ExternalEvents();
        return ExternalEvents.instance;
    }

    private trigger(evt: ExternalEvent) {
        window.parent.postMessage(evt, '*');
    }

    triggerHelOpened(externalParams?: ExternalParams) {
        const evt: HelOpened = { type: 'helOpened', ...externalParams };
        this.trigger(evt);
    }

    triggerCheckoutFormOpened(forPriceFormData: ForPriceFormData & PriceData) {
        const evt: CheckoutFormOpened = { type: 'checkoutFormOpened', ...forPriceFormData };
        this.trigger(evt);
    }

    triggerOrderOverviewOpened(checkoutFormData: CheckoutFormData & PriceData) {
        const evt: OrderOverviewOpened = { type: 'orderOverviewOpened', ...checkoutFormData };
        this.trigger(evt);
    }

    triggerCheckoutSucceeded(checkoutFormData: CheckoutFormData & PriceData & { orderId: number }) {
        const evt: CheckoutSucceeded = {
            type: 'checkoutSucceeded',
            ...checkoutFormData,
            summarizedDeliveryQuantityInLiter: checkoutFormData.deliveryAddresses.reduce((sum, deliveryAddress) => {
                console.log(deliveryAddress.deliveryQuantityInLiter, typeof deliveryAddress.deliveryQuantityInLiter);
                /**
                 * TODO: Ensure deliveryAddress.deliveryQuantityInLiter is always a number or string, not booth.
                 * We need to parseFloat the 'deliveryAddress.deliveryQuantityInLiter' because in case of multiple
                 * delivery addresses the 'deliveryAddress.deliveryQuantityInLiter' becomes a user input with the type
                 * of a string. When only a single delivery address is defined, this values comes from
                 * our system and should be a number.
                 */
                return sum + parseFloat(deliveryAddress.deliveryQuantityInLiter as unknown as string);
            }, 0),
        };
        this.trigger(evt);
    }

    triggerDesiredPriceFormOpened(forPriceFormData: ForPriceFormData & PriceData) {
        const evt: DesiredPriceFormOpened = { type: 'desiredPriceFormOpened', ...forPriceFormData };
        this.trigger(evt);
    }

    triggerDesiredPriceRequested(desiredPriceFormData: DesiredPriceFormData) {
        const evt: DesiredPriceRequested = { type: 'desiredPriceRequested', ...desiredPriceFormData };
        this.trigger(evt);
    }
}

export default ExternalEvents;