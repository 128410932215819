import {FunctionComponent, useEffect} from "react";
import CheckoutForm from "../components/CheckoutForm/CheckoutForm";
import {useNavigate, useOutletContext} from "react-router-dom";
import {CheckoutContainerContext} from "../layouts/CheckoutContainer";

const Checkout: FunctionComponent = () => {
    const { setTitle } = useOutletContext<CheckoutContainerContext>();
    const navigate = useNavigate()

    useEffect(() => {
        setTitle('Jetzt bestellen');
    }, []);

    return (
        <>
            <button className={'button'} onClick={() => navigate(-1)}>Zurück</button>
            <CheckoutForm/>
        </>
    );
}

export default Checkout;