import {FunctionComponent} from "react";
import {useNavigate} from "react-router-dom";
import {useStore} from "../hooks/useStore";
import {toPriceString} from "../utils/toPriceString";

export interface PriceBoxPrices {
    basePriceBrutto: number,
    basePriceNetto: number,
    fullPriceBrutto: number,
    lgaBrutto: number,
    lgaNetto: number,
}

interface PriceBoxProps {
    prices: PriceBoxPrices,
    cardActive: boolean,
    cardImage: string,
    cardRecommendation: boolean,
    product: 'premium' | 'standard',
    isActive: boolean
}

export const PriceBox: FunctionComponent<PriceBoxProps> = (props) => {
    const { setState } = useStore();
    const navigate = useNavigate();

    /**
     * Override toDisplayPrice from utils in this scope.
     * The real toDisplayPrice function is imported as realToDisplayPrice.
     */
    const toDisplayPrice = (number: number): string => {
        return props.isActive ? toPriceString(number) : '--,-';
    };

    const storeAndNavigate = (to: string): void => {
        setState((state) => ({
            ...state,
            product: props.product,
            basePriceBrutto: props.prices.basePriceBrutto,
            basePriceNetto: props.prices.basePriceNetto,
            fullPriceBrutto: props.prices.fullPriceBrutto,
            lgaBrutto: props.prices.lgaBrutto,
            lgaNetto: props.prices.lgaNetto,
        }));

        navigate(to);
    };

    return (
        <div className={`card hover-effect ${ props.cardActive ? 'active' : '' }`}>
            { props.cardRecommendation && (<div className='banner'>Unsere Empfehlung</div>) }
            <img
                src={props.cardImage}
                width='3062'
                height='408'
                className='img-fluid mb-2'
            />

            <p className='mb-0'><strong>Einzelpreis</strong></p>
            <p className="mb-1">
                <span className="preis">{toDisplayPrice(props.prices.basePriceBrutto)}</span> / 100 Liter<br/>
                <span className="small">inkl. 19% MwSt ({toDisplayPrice(props.prices.basePriceNetto)} zzgl. MwSt)</span>
            </p>

            <p className="mt-2 large"><strong>Gesamtpreis</strong></p>
            <p>
                <span className="preis">{toDisplayPrice(props.prices.fullPriceBrutto)}</span><br/>
                <span className="small">inkl. 19% MwSt</span>
            </p>

            <p>
                <span className="small">
                    inkl. LGA (Logistik- und Gefahrgut-Aufschlag):<br/>
                    {toDisplayPrice(props.prices.lgaBrutto)} pro Lieferstelle inkl. 19% MwSt<br/>
                    ({toDisplayPrice(props.prices.lgaNetto)} zzgl. MwSt)<br/>
                </span>
            </p>

            <button className='button block mb-0' onClick={() => storeAndNavigate('/checkout')} disabled={!props.isActive}>
                <strong>Jetzt bestellen</strong>
                <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" fill="currentColor" className="bi bi-cart-fill" viewBox="0 0 16 16"><path
                    d="M0 1.5A.5.5 0 0 1 .5 1H2a.5.5 0 0 1 .485.379L2.89 3H14.5a.5.5 0 0 1 .491.592l-1.5 8A.5.5 0 0 1 13 12H4a.5.5 0 0 1-.491-.408L2.01 3.607 1.61 2H.5a.5.5 0 0 1-.5-.5zM5 12a2 2 0 1 0 0 4 2 2 0 0 0 0-4zm7 0a2 2 0 1 0 0 4 2 2 0 0 0 0-4zm-7 1a1 1 0 1 1 0 2 1 1 0 0 1 0-2zm7 0a1 1 0 1 1 0 2 1 1 0 0 1 0-2z"/>
                </svg>
            </button>

            <button className='button block blue' onClick={() => storeAndNavigate('/desired-price')} disabled={!props.isActive}>
                <strong>Wunschpreis</strong>
                <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" fill="currentColor" className="bi bi-info-circle-fill" viewBox="0 0 16 16"><path
                    d="M8 16A8 8 0 1 0 8 0a8 8 0 0 0 0 16zm.93-9.412-1 4.705c-.07.34.029.533.304.533.194 0 .487-.07.686-.246l-.088.416c-.287.346-.92.598-1.465.598-.703 0-1.002-.422-.808-1.319l.738-3.468c.064-.293.006-.399-.287-.47l-.451-.081.082-.381 2.29-.287zM8 5.5a1 1 0 1 1 0-2 1 1 0 0 1 0 2z"/>
                </svg>
            </button>
        </div>
    )
}

export default PriceBox;