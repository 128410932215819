import {FunctionComponent, useEffect, useState} from "react";
import PriceBox, {PriceBoxPrices} from "./PriceBox";
import heizoelPremiumPlusProKlima from '../assets/images/heizoel_premium_plus_pro_klima.jpg';
import heizoelPlus from '../assets/images/heizoel_plus.svg';
import {CalculatePriceResponse} from "../utils/types";
import {useStore} from "../hooks/useStore";
import useConfig from "../hooks/useConfig";

export interface PriceSelectorProps {
    pricesActive?: boolean,
}

const PriceSelector: FunctionComponent<PriceSelectorProps> = ({ pricesActive: passedPricesActive = true }) => {
    const { state, setState } = useStore();
    const [premiumPrices, setPremiumPrices] = useState<PriceBoxPrices | null>(null);
    const [standardPrices, setStandardPrices] = useState<PriceBoxPrices | null>(null);
    const [pricesActive, setPricesActive] = useState(passedPricesActive);
    const { apiBaseUrl } = useConfig();

    useEffect(() => {
        setPricesActive(passedPricesActive);
    }, [passedPricesActive]);

    const fetchPrices = async () => {
        if (!state.forPriceFormData) return;

        const body = {
            zipcode: state.forPriceFormData.zipcode,
            deliverystation: state.forPriceFormData.amountOfDeliveryPoints,
            deliveryquantity: state.forPriceFormData.overallDeliveryQuantityInLiter,
            deliveryperiod: state.forPriceFormData.deliveryPeriod,
            deliverytime: state.forPriceFormData.deliveryTime,
            paymentmethodid: state.forPriceFormData.paymentMethod,
            truck: state.forPriceFormData.fuelTruckType,
            tube: state.forPriceFormData.hoseType,
        };

        const res = await fetch(`${apiBaseUrl}/calculatepricerequest`, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(body),
        });

        const data = await res.json() as CalculatePriceResponse;

        if (!data.success) throw new Error('Invalid response');

        setState((state) => ({ ...state, orderDedupToken: data.orderDedupToken }))
        const lgaNetto: number = typeof data.calculatedPrices.lgaNetto === 'string' ? parseFloat(data.calculatedPrices.lgaNetto) : data.calculatedPrices.lgaNetto;
        const lgaBrutto: number = typeof data.calculatedPrices.lgaBrutto === 'string' ? parseFloat(data.calculatedPrices.lgaBrutto) : data.calculatedPrices.lgaBrutto;

        setPremiumPrices({
            basePriceBrutto: data.calculatedPrices.basePricePremiumBrutto,
            basePriceNetto: data.calculatedPrices.basePricePremiumNetto,
            fullPriceBrutto: data.calculatedPrices.premiumBrutto,
            lgaNetto: lgaNetto,
            lgaBrutto: lgaBrutto,
        });

        setStandardPrices({
            basePriceBrutto: data.calculatedPrices.basePriceStandardBrutto,
            basePriceNetto: data.calculatedPrices.basePriceStandardNetto,
            fullPriceBrutto: data.calculatedPrices.standardBrutto,
            lgaNetto: lgaNetto,
            lgaBrutto: lgaBrutto,
        });
    };

    useEffect(() => {
        setPricesActive(false);
        fetchPrices().then(() => setPricesActive(true));
    }, [state.lgaNetto, state.lgaBrutto, state.forPriceFormData]);

    return (
        <>
            { premiumPrices && standardPrices && (
                <div className='row align-items-center justify-content-between'>
                    <div className='col-lg-6'>
                        <PriceBox
                            prices={premiumPrices}
                            cardActive={true}
                            cardRecommendation={true}
                            cardImage={heizoelPremiumPlusProKlima}
                            product={'premium'}
                            isActive={pricesActive}
                        />
                    </div>
                    <div className='col-lg-6'>
                        <PriceBox
                            prices={standardPrices}
                            cardActive={false}
                            cardRecommendation={false}
                            cardImage={heizoelPlus}
                            product={'standard'}
                            isActive={pricesActive}
                        />
                    </div>
                </div>
            )}
        </>
    )
}

export default PriceSelector;
