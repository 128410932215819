import {FunctionComponent, useEffect, useMemo, useState} from "react";
import {FormProvider, useForm} from "react-hook-form";
import translate, {TranslationKey} from "../../utils/translation";
import {ZipcodeLookupResponse} from "../../utils/types";
import SelectForPriceFormPart from "./SelectForPriceFormPart";
import FuelTruckForPriceFormPart from "./FuelTruckForPriceFormPart";
import PaymentMethodForPriceFormPart from "./PaymentMethodForPriceFormPart";
import {Tecson, useStore} from "../../hooks/useStore";
import InlineFormError from "../InlineFormError";
import objectHasOwnProperties from "../../utils/objectHasOwnProperties";
import objectIsEmpty from "../../utils/objectIsEmpty";
import ExternalEvents from "../../utils/ExternalEvents";
import {debounce} from "../../utils/debounce";
import useConfig from "../../hooks/useConfig";

export interface ForPriceFormData {
    zipcode: string,
    amountOfDeliveryPoints: number,
    overallDeliveryQuantityInLiter: number,
    deliveryPeriod: string,
    deliveryTime: string,
    paymentMethod: string,
    fuelTruckType: string,
    hoseType: string,
}

export type ExternalParams = Pick<ForPriceFormData, 'zipcode' | 'amountOfDeliveryPoints' | 'overallDeliveryQuantityInLiter'>;

interface ParamsHandler<T extends Object> {
    params: Array<string>,
    handle: (params: T) => void,
}

export interface ForPriceFormProps {
    onValidityChange?: (isValid: boolean) => any,
}

const ForPriceForm: FunctionComponent<ForPriceFormProps> = ({ onValidityChange = () => {} }) => {
    const formMethods = useForm<ForPriceFormData>();
    const { register, handleSubmit, watch, getValues, setError, formState: { isValid, errors }, reset} = formMethods;
    const [zipcodeLookupData, setZipcodeLookupData] = useState<ZipcodeLookupResponse | null>(null);
    const { setState } = useStore();
    const { apiBaseUrl } = useConfig()

    const hubSpotParams: ParamsHandler<{ zipcode: string, deliverystations: string, deliveryquantity: string }> = {
        params: [ 'zipcode', 'deliverystations', 'deliveryquantity' ],
        handle: (params) => {
            const externalParams: ExternalParams = {
                zipcode: params.zipcode,
                amountOfDeliveryPoints: parseInt(params.deliverystations),
                overallDeliveryQuantityInLiter: parseFloat(params.deliveryquantity),
            };

            if (externalParams.zipcode === '' || isNaN(externalParams.amountOfDeliveryPoints) || isNaN(externalParams.overallDeliveryQuantityInLiter)) {
                ExternalEvents.getInstance().triggerHelOpened();
                return;
            }

            ExternalEvents.getInstance().triggerHelOpened(externalParams);

            reset(externalParams);

            onZipcodeChange(externalParams.zipcode);
            handleSubmit(onSubmit)();
        },
    };

    const tecsonParams: ParamsHandler<{ 'partner-id': string, 'call-id': string, date: string, zip: string, quantity: string, deliverypoints: string, src: string }> = {
        params: [ 'partner-id','call-id', 'date', 'zip', 'quantity', 'deliverypoints', 'src' ],
        handle: (params) => {
            const tecson: Tecson = {
                partnerId: params['partner-id'],
                callId: params['call-id'],
                date: params.date,
                zip: params.zip,
                quantity: parseFloat(params.quantity),
                deliverypoints: parseInt(params.deliverypoints),
            }

            if (
                tecson.partnerId === '' ||
                tecson.callId === '' ||
                tecson.date === '' ||
                tecson.zip === '' ||
                isNaN(tecson.quantity) ||
                isNaN(tecson.deliverypoints) ||
                params.src !== 'tecson'
            ) {
                return;
            }

            hubSpotParams.handle({ zipcode: params.zip, deliverystations: params.deliverypoints, deliveryquantity: params.quantity });

            setState((state) => ({
                ...state,
                tecson: tecson,
            }));
        },
    };

    const handleParams = (handlers: ParamsHandler<any>[]) => {
        const params = Object.fromEntries((new URLSearchParams(window.location.search)).entries());
        console.log(params);
        const correctHandlers = handlers.filter((handler) => objectHasOwnProperties(params, handler.params));

        if (correctHandlers.length !== 1) {
            console.warn(`No params-handler was used, found ${correctHandlers.length} handlers.`);
            ExternalEvents.getInstance().triggerHelOpened();
            return;
        } else {
            console.log('correctHandlers', correctHandlers);
        }

        const handler = correctHandlers[0];
        handler.handle(Object.fromEntries(Object.entries(params).filter(([key]) => handler.params.includes(key))));
    };

    // handle default values via parameters
    useEffect(() => {
        handleParams([ hubSpotParams, tecsonParams ]);
    }, []);

    useEffect(() => onValidityChange(isValid || objectIsEmpty(errors)), [isValid, errors]);

    const onSubmit = (data: ForPriceFormData) => {
        setState((state) => ({
            ...state,
            forPriceFormData: data,
        }));
    };

    useEffect(() => {
        const subscription = watch((value, { type }) => {
            if (type === 'change' && value.zipcode !== undefined) {
                onZipcodeChange(value.zipcode);
            } else {
                handleSubmit(onSubmit)();
            }
        });
        return () => subscription.unsubscribe();
    }, [watch]);

    const onZipcodeChange = useMemo(() => debounce((zipcode: string) => {
        fetch(`${apiBaseUrl}/options/${zipcode}`)
            .then((res) => {
                if (res.status !== 200) throw new Error(`Invalid status code, expected 200 got ${res.status}`);
                return res.json();
            })
            .then((data: ZipcodeLookupResponse) => {
                if (!data.success) throw new Error('Zipcode not found!');
                setZipcodeLookupData(data);

                const lga = data.data.staffelpreis[0]?.gefahrgutpauschale;
                if (lga) {
                    setState((state) => ({ ...state,
                        lgaBrutto: parseFloat(lga) * 1.19,
                        lgaNetto: parseFloat(lga),
                    }));
                }

                setState((state) => ({
                    ...state,
                    salesOfficePhoneNumber: data.data.vk.phone,
                    availablePaymentMethods: data.data.zahlungsarten,
                    availableDeliveryPeriods: data.data.lieferfristen,
                    availableDeliveryTimes: data.data.lieferzeit,
                    availableTrucks: data.data.tkws,
                    availableHoses: data.data.schlauch
                }));
            })
            .catch((err) => {
                console.log(err);
                setError('zipcode', { type: 'zipcode-not-found' });
            });
    }, 500), []);

    const getDeliveryPointOptions = (maxPoints = 10): JSX.Element[] => {
        let options: JSX.Element[] = [];
        for (let i = 1; i <= maxPoints; i++) {
            const option = (<option key={i} value={i}>{i}</option>)
            options = [ ...options, option ];
        }
        return options;
    };

    const validateDeliveryQuantity = (): boolean => {
        const [amountOfDeliveryPoints, overallDeliveryQuantityInLiter] = getValues(['amountOfDeliveryPoints', 'overallDeliveryQuantityInLiter']);
        const possibleAmountOfDeliveryPoints = Math.abs(overallDeliveryQuantityInLiter / 500);
        return possibleAmountOfDeliveryPoints >= amountOfDeliveryPoints;
    };

    return (
        <FormProvider {...formMethods}>
            <form onSubmit={handleSubmit(onSubmit)}>
                <label>{translate(TranslationKey.Zipcode)}</label>
                <input {...register('zipcode', { required: { value: true, message: translate(TranslationKey.RequiredFieldError) }})} type= {"text"} className={'form-control'} placeholder={'z.B. 35392'} />
                <InlineFormError name={'zipcode'} messages={{'zipcode-not-found': translate(TranslationKey.ZipcodeNotFound)}} />

                <div className='row'>
                    <div className='col-4'>
                        <label>{translate(TranslationKey.DeliveryPoint)}</label>
                        <select {...register('amountOfDeliveryPoints', {
                            required: true
                        })} className={'form-select'}>
                            {getDeliveryPointOptions(10)}
                        </select>
                    </div>

                    <div className='col-8'>
                        <label>{translate(TranslationKey.DeliveryQuantity)}</label>
                        <div className='input-group'>
                            <input {...register('overallDeliveryQuantityInLiter', {
                                required: { value: true, message: translate(TranslationKey.RequiredFieldError) },
                                validate: { 'delivery-quantity-out-of-bounds': validateDeliveryQuantity },
                                min: 500,
                                max: 32000
                            })} className={'form-control'} placeholder={'z.B. 500'}/>
                            <span className="input-group-text">Liter</span>
                        </div>
                        <InlineFormError name={'overallDeliveryQuantityInLiter'} messages={{
                            'delivery-quantity-out-of-bounds': translate(TranslationKey.DeliveryQuantityOutOfBounceError),
                            min: translate(TranslationKey.DeliveryQuantityOutOfBounceError),
                            max: translate(TranslationKey.DeliveryQuantityOutOfBounceError)
                        }} />
                    </div>
                </div>

                <SelectForPriceFormPart label={translate(TranslationKey.DeliveryPeriod)} formRegisterName={'deliveryPeriod'} options={zipcodeLookupData?.data.lieferfristen || []}/>

                <SelectForPriceFormPart label={translate(TranslationKey.DeliveryTime)} formRegisterName={'deliveryTime'} options={zipcodeLookupData?.data.lieferzeit || []}/>

                <PaymentMethodForPriceFormPart options={zipcodeLookupData?.data.zahlungsarten || []}/>

                <FuelTruckForPriceFormPart options={zipcodeLookupData?.data.tkws || []} />

                <SelectForPriceFormPart label={translate(TranslationKey.Hose)} formRegisterName={'hoseType'} options={zipcodeLookupData?.data.schlauch || []}/>

                <button type="submit" className="button block"><strong>Jetzt berechnen</strong>
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" className="bi bi-calculator-fill" viewBox="0 0 16 16">
                        <path d="M2 2a2 2 0 0 1 2-2h8a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V2zm2 .5v2a.5.5 0 0 0 .5.5h7a.5.5 0 0 0 .5-.5v-2a.5.5 0 0 0-.5-.5h-7a.5.5 0 0 0-.5.5zm0 4v1a.5.5 0 0 0 .5.5h1a.5.5 0 0 0 .5-.5v-1a.5.5 0 0 0-.5-.5h-1a.5.5 0 0 0-.5.5zM4.5 9a.5.5 0 0 0-.5.5v1a.5.5 0 0 0 .5.5h1a.5.5 0 0 0 .5-.5v-1a.5.5 0 0 0-.5-.5h-1zM4 12.5v1a.5.5 0 0 0 .5.5h1a.5.5 0 0 0 .5-.5v-1a.5.5 0 0 0-.5-.5h-1a.5.5 0 0 0-.5.5zM7.5 6a.5.5 0 0 0-.5.5v1a.5.5 0 0 0 .5.5h1a.5.5 0 0 0 .5-.5v-1a.5.5 0 0 0-.5-.5h-1zM7 9.5v1a.5.5 0 0 0 .5.5h1a.5.5 0 0 0 .5-.5v-1a.5.5 0 0 0-.5-.5h-1a.5.5 0 0 0-.5.5zm.5 2.5a.5.5 0 0 0-.5.5v1a.5.5 0 0 0 .5.5h1a.5.5 0 0 0 .5-.5v-1a.5.5 0 0 0-.5-.5h-1zM10 6.5v1a.5.5 0 0 0 .5.5h1a.5.5 0 0 0 .5-.5v-1a.5.5 0 0 0-.5-.5h-1a.5.5 0 0 0-.5.5zm.5 2.5a.5.5 0 0 0-.5.5v4a.5.5 0 0 0 .5.5h1a.5.5 0 0 0 .5-.5v-4a.5.5 0 0 0-.5-.5h-1z"/>
                    </svg>
                </button>
            </form>
        </FormProvider>
    )
}

export default ForPriceForm;