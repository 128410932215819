import {FunctionComponent} from "react";
import {toPriceString} from "../utils/toPriceString";
import {useStore} from "../hooks/useStore";

const OrderOverviewTable: FunctionComponent = () => {
    const { state } = useStore();

    const lga = state.lgaNetto;
    const deliveryStations = state.forPriceFormData?.amountOfDeliveryPoints || 1;
    const fullLgaNetto = deliveryStations * state.lgaNetto;

    const totalPriceNetto = state.fullPriceBrutto / 1.19;
    const totalPriceLga = Math.abs(totalPriceNetto - fullLgaNetto);
    const zwischensumme = Math.abs(fullLgaNetto + totalPriceLga);
    const taxAmount = Math.abs(0.19 * zwischensumme);
    const deliveryQuantity = state.forPriceFormData?.overallDeliveryQuantityInLiter || 0;
    const displayPrice = Math.abs(totalPriceLga / (deliveryQuantity / 100));

    return (
        <div>
            <h4>Ihre Bestellung</h4>

            <div className='table-responsive'>
                <table>
                    <thead>
                    <tr>
                        <th>Menge</th>
                        <th>Beschreibung</th>
                        <th>Einzelpreis</th>
                        <th className='text-right'>Gesamtpreis</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr>
                        <td>{state.forPriceFormData?.overallDeliveryQuantityInLiter} Liter</td>
                        <td>ROTH Heizöl {state.product === 'premium' ? 'Premium Plus PRO Klima' : 'Plus'}</td>
                        <td>{toPriceString(displayPrice)} / 100 Liter</td>
                        <td className='text-right'>{toPriceString(totalPriceLga)}</td>
                    </tr>
                    <tr>
                        <td>{deliveryStations}</td>
                        <td>LGA (Logistik- und Gefahrgut-Aufschlag):</td>
                        <td>{toPriceString(lga)} / Lieferstelle</td>
                        <td className='text-right'>{toPriceString(fullLgaNetto)}</td>
                    </tr>
                    <tr>
                        <td></td>
                        <td></td>
                        <td>Zwischensumme</td>
                        <td className='text-right'>{toPriceString(zwischensumme)}</td>
                    </tr>
                    <tr>
                        <td></td>
                        <td></td>
                        <td className='border'>+ 19% MwSt.</td>
                        <td className='text-right border'>{toPriceString(taxAmount)}</td>
                    </tr>
                    <tr>
                        <td></td>
                        <td></td>
                        <td className='double-border'><strong>Endpreis inkl. Lieferung:</strong></td>
                        <td className='text-right double-border'><strong>{toPriceString(state.fullPriceBrutto)}</strong></td>
                    </tr>
                    </tbody>
                </table>
            </div>
        </div>
    );
};

export default OrderOverviewTable;