import {FunctionComponent} from "react";
import {useFormContext} from "react-hook-form";
import {CheckoutFormData} from "./CheckoutForm";
import translate, {TranslationKey} from "../../utils/translation";
import SelectForPriceFormPart from "../ForPriceForm/SelectForPriceFormPart";
import {useStore} from "../../hooks/useStore";
import InlineFormError, {WithErrorBorder} from "../InlineFormError";
import {paymentMethodIsSepa} from "../../hooks/useConfig";
import {isValidBIC, isValidIBAN} from "ibantools";

const DeliveryOptionsCheckoutFormPart: FunctionComponent = () => {
    const { state } = useStore();
    const { register, getValues } = useFormContext<CheckoutFormData>();

    return (
        <>
            <h4 className='mt-2'>Zahlungsart / Lieferoptionen</h4>

            <SelectForPriceFormPart label={translate(TranslationKey.PaymentMethod) + '*'} formRegisterName={'deliveryOptions.paymentMethod'} options={state.availablePaymentMethods} defaultOptionId={state.forPriceFormData?.paymentMethod} readonly={true}/>

            { paymentMethodIsSepa(getValues('deliveryOptions.paymentMethod')) && (
                <div className='row'>
                    {/* Example data for IBAN and BIC validation: https://ibanvalidieren.de/beispiele.html */}

                    <div className='col'>
                        <label>IBAN*</label>
                        <input {
                            ...register('deliveryOptions.iban', {
                                validate: {
                                    'invalid-iban': (value) => isValidIBAN(value),
                                },
                                required: {
                                    value: true,
                                    message: translate(TranslationKey.RequiredFieldError)
                                }
                            })}
                            type='text'
                            className={WithErrorBorder('deliveryOptions.iban', 'form-control')}
                        />
                        <InlineFormError name={'deliveryOptions.iban'} messages={{ 'invalid-iban': 'Ungültige IBAN' }}/>
                    </div>

                    <div className='col'>
                        <label>BIC</label>
                        <input {
                            ...register('deliveryOptions.bic', {
                                validate: {
                                    'invalid-bic': (value) => value === '' || isValidBIC(value)
                                }
                            })}
                            type='text'
                            className={WithErrorBorder('deliveryOptions.bic', 'form-control')}
                        />
                        <InlineFormError name={'deliveryOptions.bic'} messages={{ 'invalid-bic': 'Ungültige BIC' }}/>
                    </div>
                </div>
            )}

            <SelectForPriceFormPart label={translate(TranslationKey.DeliveryPeriod) + '*'} formRegisterName={'deliveryOptions.deliveryPeriod'} options={state.availableDeliveryPeriods} defaultOptionId={state.forPriceFormData?.deliveryPeriod} readonly={true}/>

            <label>Sonstiges</label>
            <textarea
                {...register('deliveryOptions.comment')}
                className='form-control'
            />

            <label>Kundennummer</label>
            <input {...register('deliveryOptions.customerNumber')} type='text' className='form-control' />

            <div className='form-check'>
                <input  {...register('deliveryOptions.termsAccepted')} required={true} className='form-check-input' type='checkbox' />
                <label className='form-check-label'>
                    Die <a href="https://www.roth-energie.de/agb" target="_blank">
                    Allgemeinen Geschäftsbedingungen
                    </a> habe ich zur
                    Kenntnis genommen und mit ihrer Geltung bin ich
                    einverstanden.
                </label>
            </div>
        </>
    );
};

export default DeliveryOptionsCheckoutFormPart;
