import {FunctionComponent} from "react";

type PayButtonProps = {
    onCheckout?: () => void
    active?: boolean
}

const paragraphs = [
    'Der Widerruf der Heizölbestellung ist ausgeschlossen. Für Heizölverbraucherkunden gibt es kein gesetzliches Widerrufsrecht (vgl. §312 g Abs 2 Nr. 8 BGB).',
    'Beim Heizölkauf besteht das gesetzliche Widerrufsrecht für Verbraucherkunden nicht, weil auf Verträge über die Lieferung von Heizöl der Ausschlussgrund des §312 g Abs 2 Nr. 8 BGB anwendbar ist. Verbraucher können ihre auf Abschluss des Vertrages gerichtete Willenserklärung also nicht widerrufen.'
];

const PayButton: FunctionComponent<PayButtonProps> = ({ onCheckout = () => {}, active = true }) => {
    return (
        <div>
            <ol style={{ 'fontSize': '0.875rem' }}>
                {
                    paragraphs.map((paragraph, index) => (
                        <li><b>{index + 1}.</b> {paragraph}</li>
                    ))
                }
            </ol>
            <button onClick={onCheckout} className="button" disabled={!active}><strong>Jetzt zahlungspflichtig bestellen</strong></button>
        </div>
    );
}

export default PayButton;
