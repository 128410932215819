import {
    createContext,
    Dispatch,
    FunctionComponent,
    PropsWithChildren,
    SetStateAction,
    useContext,
    useState
} from "react";
import {ForPriceFormData} from "../components/ForPriceForm/ForPriceForm";
import {CheckoutFormData} from "../components/CheckoutForm/CheckoutForm";
import {
    ZipcodeLookupLieferfristResponse,
    ZipcodeLookupLieferzeitResponse,
    ZipcodeLookupSchlauchResponse,
    ZipcodeLookupTkwResponse,
    ZipcodeLookupZahlunsartenResponse
} from "../utils/types";

export interface Tecson {
    partnerId: string,
    callId: string,
    date: string,
    zip: string,
    quantity: number,
    deliverypoints: number,
}

export interface StoreState {
    lgaBrutto: number,
    lgaNetto: number,
    basePriceBrutto: number,
    basePriceNetto: number
    fullPriceBrutto: number,
    product: 'premium' | 'standard' | null,
    salesOfficePhoneNumber: string,
    availablePaymentMethods: ZipcodeLookupZahlunsartenResponse[],
    availableDeliveryPeriods: ZipcodeLookupLieferfristResponse[],
    availableDeliveryTimes: ZipcodeLookupLieferzeitResponse[],
    availableTrucks: ZipcodeLookupTkwResponse[],
    availableHoses: ZipcodeLookupSchlauchResponse[],
    forPriceFormData: ForPriceFormData | null,
    checkoutFormData: CheckoutFormData | null,
    tecson: Tecson | null,
    orderDedupToken: string | null,
}

export type PriceData = Pick<StoreState, 'product' | 'basePriceBrutto' | 'basePriceNetto' | 'fullPriceBrutto'  | 'lgaBrutto'  | 'lgaNetto'>;

export const getPriceDataFromStoreData = (state: StoreState): PriceData => ({
    product: state.product,
    basePriceBrutto: state.basePriceBrutto,
    basePriceNetto: state.basePriceNetto,
    fullPriceBrutto: state.fullPriceBrutto,
    lgaBrutto: state.lgaBrutto,
    lgaNetto: state.lgaNetto,
});

interface Store {
    setState: Dispatch<SetStateAction<StoreState>>,
    state: StoreState
}

const emptyStoreData: StoreState = {
    lgaBrutto: 0,
    lgaNetto: 0,
    basePriceBrutto: 0,
    basePriceNetto: 0,
    fullPriceBrutto: 0,
    product: null,
    salesOfficePhoneNumber: '',
    availablePaymentMethods: [],
    availableDeliveryPeriods: [],
    availableDeliveryTimes: [],
    availableTrucks: [],
    availableHoses: [],
    forPriceFormData: null,
    checkoutFormData: null,
    tecson: null,
    orderDedupToken: null,
}

const initialStoreState: StoreState = emptyStoreData;

const StoreContext = createContext<Store>({
    state: initialStoreState, setState: (_: {}) => {},
});

export const useStore = () => useContext(StoreContext);

export const ProvideStore: FunctionComponent<PropsWithChildren> = ({ children }) => {
    const [state, setState] = useState<StoreState>(initialStoreState);

    return (
        <StoreContext.Provider value={{
            state,
            setState,
        }}>
            {children}
        </StoreContext.Provider>
    );
};
