import {FunctionComponent, useEffect} from "react";
import {NavigateProps, useLocation, useOutletContext} from "react-router-dom";
import {CheckoutContainerContext} from "../layouts/CheckoutContainer";
import ExternalEvents from "../utils/ExternalEvents";
import {getPriceDataFromStoreData, useStore} from "../hooks/useStore";
import {CheckoutSucceededNavigateState} from "../utils/types";

const CheckoutSucceeded: FunctionComponent<NavigateProps> = (props) => {
    const { state } = useStore();
    const { setTitle, setSubtitle } = useOutletContext<CheckoutContainerContext>();
    const { state: navigateState } = useLocation();

    useEffect(() => {
        const orderId = (navigateState as CheckoutSucceededNavigateState)?.order?.id;
        if (state?.checkoutFormData && orderId)
            ExternalEvents.getInstance().triggerCheckoutSucceeded({
                ...state.checkoutFormData,
                ...getPriceDataFromStoreData(state),
                orderId: orderId,
            });

        setTitle('Bestellung erfolgreich versendet');
        setSubtitle(' ');
    }, []);

    return (
        <>
            <h4>Vielen Dank</h4>
            <p>Vielen Dank für Ihre Bestellung und Ihr uns entgegengebrachtes Vertrauen. Wir werden uns bei Ihnen zwecks Liefertermin melden.</p>
        </>
    );
};

export default CheckoutSucceeded;