import { FunctionComponent, useEffect, useState } from "react";
import {useNavigate, useOutletContext} from "react-router-dom";
import {CheckoutContainerContext} from "../layouts/CheckoutContainer";
import {getPriceDataFromStoreData, useStore} from "../hooks/useStore";
import OrderOverviewTable from "../components/OrderOverviewTable";
import {
    CheckoutSucceededNavigateState,
    SendPriceRequestResponse,
    ZipcodeLookupLieferfristResponse,
    ZipcodeLookupLieferzeitResponse,
    ZipcodeLookupSchlauchResponse,
    ZipcodeLookupTkwResponse,
    ZipcodeLookupZahlunsartenResponse
} from "../utils/types";
import ExternalEvents from "../utils/ExternalEvents";
import PayButton from "../components/PayButton";
import {submitHubSpotForm} from "../utils/hubspot";
import useConfig, {paymentMethodIsSepa, paymentMethodNeedsDateOfBirth} from "../hooks/useConfig";

const ConfirmCheckout: FunctionComponent = (props) => {
    const { state } = useStore();
    const { setTitle, setSubtitle } = useOutletContext<CheckoutContainerContext>();
    const navigate = useNavigate();
    const { apiBaseUrl, enableHubSpotIntegration, hubSpotPortalId, hubSpotFormId } = useConfig()
    const [isCheckoutInProgress, setCheckoutInProgress] = useState(false)

    useEffect(() => {
        if (state?.checkoutFormData)
            ExternalEvents.getInstance().triggerOrderOverviewOpened({
                ...state.checkoutFormData,
                ...getPriceDataFromStoreData(state)
            });

        setTitle('Daten ändern');
        setSubtitle('Bitte überprüfen Sie nochmals die von Ihnen eingegebenen Daten. Klicken Sie auf "Daten anpassen", um diese zu korrigieren, oder klicken Sie auf "Jetzt Kaufen", um die Bestellung abzuschließen.');
    }, []);

    const submitCheckoutFormToHubSpot = async (form: {
        email: string,
        salutation: string,
        firstname: string,
        lastname: string,
        product: string,
        fullPriceBrutto: number,
        overallDeliveryQuantity: number,
    }) => submitHubSpotForm(hubSpotPortalId, hubSpotFormId, [
        {
            objectTypeId: '0-1',
            name: 'email',
            value: form.email
        },
        {
            objectTypeId: '0-1',
            name: 'salutation',
            value: form.salutation
        },
        {
            objectTypeId: '0-1',
            name: 'firstname',
            value: form.firstname
        },
        {
            objectTypeId: '0-1',
            name: 'lastname',
            value: form.lastname
        },
        {
            objectTypeId: '0-1',
            name: 'hel_product',
            value: form.product
        },
        {
            objectTypeId: '0-1',
            name: 'hel_full_price_brutto',
            value: form.fullPriceBrutto
        },
        {
            objectTypeId: '0-1',
            name: 'hel_overall_delivery_quantity',
            value: form.overallDeliveryQuantity
        },
    ])

    const doCheckout = () => {
        setCheckoutInProgress(true)

        const body = {
            zipcode: state.forPriceFormData?.zipcode,
            deliveryquantity: state.forPriceFormData?.overallDeliveryQuantityInLiter,
            deliverystation: state.forPriceFormData?.amountOfDeliveryPoints,
            salutation: state.checkoutFormData?.deliveryAddresses[0].salutation,
            'invoice-same-as-delivery': state.checkoutFormData?.invoiceAddressSameAsDeliveryAddress,
            email: state.checkoutFormData?.personal.email,
            other: state.checkoutFormData?.deliveryOptions.comment,
            customernumber: state.checkoutFormData?.deliveryOptions.customerNumber,
            privacy: state.checkoutFormData?.deliveryOptions.termsAccepted,
            deliveryperiod: state.checkoutFormData?.deliveryOptions.deliveryPeriod,
            deliverytime: state.forPriceFormData?.deliveryTime,
            tube: state.forPriceFormData?.hoseType,
            paymentmethod: state.checkoutFormData?.deliveryOptions.paymentMethod,
            paymentmethodid: state.checkoutFormData?.deliveryOptions.paymentMethod,
            truck: state.forPriceFormData?.fuelTruckType,
            truckid: state.forPriceFormData?.fuelTruckType,
            invoiceSameDelivery: state.checkoutFormData?.invoiceAddressSameAsDeliveryAddress,
            isPremium: state.product === 'premium',
            product: state.product,
            deliveryTimeName: getSelectedDeliveryTime()?.name,
            deliveryTimePrice: getSelectedDeliveryTime()?.children.price,
            deliveryPeriodName: getSelectedDeliveryPeriod()?.name,
            deliveryPeriodPrice: getSelectedDeliveryPeriod()?.children.price,
            paymentMethodName: getSelectedPaymentMethod()?.name,
            paymentMethodPrice: getSelectedPaymentMethod()?.children.price,
            truckName: getSelectedTruck()?.name,
            truckPrice: getSelectedTruck()?.children.price,
            tubeName: getSelectedHose()?.name,
            tubePrice: getSelectedHose()?.children.price,
            delivery: state.checkoutFormData?.deliveryAddresses.map((deliveryAddress, index) => {
                return {
                    salutation: deliveryAddress.salutation,
                    firstname: deliveryAddress.firstName,
                    lastname: deliveryAddress.lastName,
                    company: deliveryAddress.company,
                    // The first deliveryAddress uses the personal phone number
                    phoneprefix: index === 0 ? state.checkoutFormData?.personal.phonePrefix : deliveryAddress.phonePrefix,
                    phone: index === 0 ? state.checkoutFormData?.personal.phoneNumber : deliveryAddress.phoneNumber,
                    street: deliveryAddress.street,
                    housenumber: deliveryAddress.housenumber,
                    deliveryzipcode: deliveryAddress.zipcode,
                    place: deliveryAddress.city,
                    district: deliveryAddress.district,
                    liefermenge: deliveryAddress.deliveryQuantityInLiter,
                    rented: deliveryAddress.rented,
                };
            }),
            invoice: [
                {
                    salutation: state.checkoutFormData?.invoiceAddress.salutation,
                    firstname: state.checkoutFormData?.invoiceAddress.firstName,
                    lastname: state.checkoutFormData?.invoiceAddress.lastName,
                    company: state.checkoutFormData?.invoiceAddress.company,
                    phoneprefix: state.checkoutFormData?.invoiceAddress.phonePrefix,
                    phone: state.checkoutFormData?.invoiceAddress.phoneNumber,
                    street: state.checkoutFormData?.invoiceAddress.street,
                    housenumber: state.checkoutFormData?.invoiceAddress.housenumber,
                    deliveryzipcode: state.checkoutFormData?.invoiceAddress.zipcode,
                    place: state.checkoutFormData?.invoiceAddress.city,
                    district: state.checkoutFormData?.invoiceAddress.district,
                }
            ],
            orderDedupToken: state.orderDedupToken,
        };

        if (state.tecson) {
            // @ts-ignore
            body['tecson'] = state.tecson;
        }

        // Append dateOfBirth only when payment method is sepa
        if (getSelectedPaymentMethod()?.id && paymentMethodNeedsDateOfBirth(getSelectedPaymentMethod()!.id)) {
            // @ts-ignore
            body['dateOfBirth'] = new Date(state.checkoutFormData?.personal.dateOfBirth).toISOString();
        }

        if (getSelectedPaymentMethod()?.id && paymentMethodIsSepa(getSelectedPaymentMethod()!.id)) {
            // @ts-ignore
            body['iban'] = state.checkoutFormData?.deliveryOptions.iban;

            // @ts-ignore
            body['bic'] = state.checkoutFormData?.deliveryOptions.bic;
        }

        fetch(`${apiBaseUrl}/sendpricerequest`, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(body),
        })
            .then((res) => {
                if (res.status !== 200) throw new Error(`Invalid status code, expected 200 got ${res.status}`);
                return res.json();
            })
            .then((data: SendPriceRequestResponse) => {
                if (!data.success) throw new Error(`Invalid response ${JSON.stringify(data)}`);

                const salutation = body.salutation ?? '';
                const firstname = (body.invoiceSameDelivery ? body.delivery![0].firstname : body.invoice[0].firstname) ?? ''
                const lastname = (body.invoiceSameDelivery ? body.delivery![0].lastname : body.invoice[0].lastname) ?? ''

                if (enableHubSpotIntegration) {
                    submitCheckoutFormToHubSpot({
                        email: body.email ?? '',
                        salutation: salutation,
                        firstname: firstname,
                        lastname: lastname,
                        product: body.product ?? '',
                        fullPriceBrutto: state.fullPriceBrutto,
                        overallDeliveryQuantity: state.forPriceFormData?.overallDeliveryQuantityInLiter ?? 0,
                    }).then(() => {
                        console.log('Successfully send checkout data to HubSpot');
                    }).catch(console.error);
                }

                navigate('/checkout-succeeded', {
                    state: { order: data.order } as CheckoutSucceededNavigateState
                });
            })
            .catch(console.error)
            .finally(() => {
                setCheckoutInProgress(false)
            });
    };

    const getSelectedPaymentMethod = (): ZipcodeLookupZahlunsartenResponse | undefined => {
        const paymentMethodId = state.checkoutFormData?.deliveryOptions.paymentMethod;
        return state.availablePaymentMethods.find((paymentMethod) => paymentMethod.id === paymentMethodId);
    };

    const getSelectedDeliveryPeriod = (): ZipcodeLookupLieferfristResponse | undefined => {
        const deliveryPeriodId = state.checkoutFormData?.deliveryOptions.deliveryPeriod;
        return state.availableDeliveryPeriods.find((deliveryPeriod) => deliveryPeriod.id === deliveryPeriodId);
    };

    const getSelectedDeliveryTime = (): ZipcodeLookupLieferzeitResponse | undefined => {
        const deliveryTimeId = state.forPriceFormData?.deliveryTime;
        return state.availableDeliveryTimes.find((deliveryTime) => deliveryTime.id === deliveryTimeId);
    };

    const getSelectedTruck = (): ZipcodeLookupTkwResponse | undefined => {
        const truckId = state.forPriceFormData?.fuelTruckType;
        return state.availableTrucks.find((truck) => truck.id === truckId);
    };

    const getSelectedHose = (): ZipcodeLookupSchlauchResponse | undefined => {
        const hoseId = state.forPriceFormData?.hoseType;
        return state.availableHoses.find((hose) => hose.id === hoseId);
    };

    const dateOfBirthFormatted = (): string | null => {
        if (!state.checkoutFormData?.personal.dateOfBirth) return null;
        const dateOfBirth = new Date(state.checkoutFormData.personal.dateOfBirth);
        return new Intl.DateTimeFormat('de-DE', {
            day: '2-digit',
            month: '2-digit',
            year: 'numeric'
        }).format(dateOfBirth);
    }

    return (
        <>
            <button className={'button'} onClick={() => navigate(-1)}>Daten anpassen</button>

            <OrderOverviewTable/>

            <div>
                <h4>Persönliche Informationen</h4>
                <p>
                    E-Mail: {state.checkoutFormData?.personal.email}<br/>
                    Tel: {state.checkoutFormData?.personal.phonePrefix} {state.checkoutFormData?.personal.phoneNumber}<br/>
                    { dateOfBirthFormatted() && (<>
                        Geburtsdatum: { dateOfBirthFormatted() }
                    </>)}
                </p>
            </div>

            <div>
                <h4>Rechnungsadresse</h4>
                {
                    state.checkoutFormData?.invoiceAddressSameAsDeliveryAddress === false ? (
                        <p>
                            {state.checkoutFormData.invoiceAddress.salutation} {state.checkoutFormData.invoiceAddress.firstName} {state.checkoutFormData.invoiceAddress.lastName}<br/>
                            {state.checkoutFormData.invoiceAddress.street} {state.checkoutFormData.invoiceAddress.housenumber}<br/>
                            {state.checkoutFormData.invoiceAddress.zipcode} {state.checkoutFormData.invoiceAddress.city} {state.checkoutFormData.invoiceAddress.district}
                        </p>
                    ) : (
                        <p>Die Rechnungsadresse ist die gleiche wie die Lieferadresse 1.</p>
                    )
                }
            </div>

            <div>
                {
                    state.checkoutFormData?.deliveryAddresses.map((deliveryAddress, index) => (
                        <div key={index}>
                            <h4>Lieferadresse {index + 1}</h4>
                            <p>
                                {deliveryAddress.salutation} {deliveryAddress.firstName} {deliveryAddress.lastName}<br/>
                                {deliveryAddress.street} {deliveryAddress.housenumber}<br/>
                                {deliveryAddress.zipcode} {deliveryAddress.city} {deliveryAddress.district}<br/>
                                { deliveryAddress.rented && (<>
                                    Vermietet / Teilvermietet<br/>
                                </>)}
                                <strong>Liefermenge:</strong> {deliveryAddress.deliveryQuantityInLiter} Liter
                            </p>
                        </div>
                    ))
                }
            </div>

            <div>
                <h4>Zahlungsart/Lieferoptionen</h4>
                <p>
                    <strong>Zahlungsart:</strong> {getSelectedPaymentMethod()?.name}<br/>
                    { state.checkoutFormData?.deliveryOptions.iban && state.checkoutFormData?.deliveryOptions.bic && (<>
                        <strong>IBAN / BIC:</strong> {state.checkoutFormData?.deliveryOptions.iban} / {state.checkoutFormData?.deliveryOptions.bic}<br/>
                    </>)}
                    <strong>Lieferfrist:</strong> {getSelectedDeliveryPeriod()?.name}<br/>
                    {state.checkoutFormData?.deliveryOptions.comment && (
                        <>
                            <strong>Kommentar:</strong> "{state.checkoutFormData.deliveryOptions.comment}"<br/>
                        </>
                    )}
                </p>
            </div>

            <div className="mt-2">
                <PayButton
                  onCheckout={doCheckout}
                  active={!isCheckoutInProgress}
                />
            </div>
        </>
    );
};

export default ConfirmCheckout;
