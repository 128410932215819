import {FunctionComponent, useEffect} from "react";
import {ZipcodeLookupTkwResponse} from "../../utils/types";
import translate, {TranslationKey} from "../../utils/translation";
import {useFormContext} from "react-hook-form";
import InlineFormError from "../InlineFormError";

interface FuelTruckForPriceFormPartProps {
    options: ZipcodeLookupTkwResponse[],
    formRegisterName?: string,
    defaultOptionId?: string,
};

const FuelTruckForPriceFormPart: FunctionComponent<FuelTruckForPriceFormPartProps> = ({ options, defaultOptionId, formRegisterName = 'fuelTruckType' }) => {
    const { register, setValue, getValues } = useFormContext();

    const resolveDefaultOptionId = (): string | undefined => {
        if (getValues(formRegisterName)) return getValues(formRegisterName);
        if (defaultOptionId) return defaultOptionId;

        let defaultOption = options.find((option) => option.isDefault);
        if (!defaultOption && options.length >= 1) defaultOption = options[0];
        return defaultOption?.id;
    };

    useEffect(() => {
        const defaultOptionId = resolveDefaultOptionId();
        if (defaultOptionId) setValue(formRegisterName, defaultOptionId);
    }, [options, defaultOptionId]);

    const getClassNameForFuelTruckName = (name: string): string => {
        switch (name) {
            case 'TKW mit Hänger':
                return 'groß';
            case 'TKW ohne Hänger':
                return 'mittel';
            case 'max. 10 t':
                return 'klein';
            default:
                return name;
        }
    };

    return (
        <>
            <label>{translate(TranslationKey.FuelTruck)}</label>
            <div className={'cc-selector row'}>
                {
                    options.map((option, idx) => (
                        <div className={'col mb-1'} key={`${formRegisterName}-${idx}`}>
                            <input
                                {...register(formRegisterName, { required: { value: true, message: translate(TranslationKey.RequiredFieldError) } })}
                                id={`${formRegisterName}-${option.id}`}
                                type={'radio'}
                                value={option.id}
                            />
                            <InlineFormError name={formRegisterName}/>
                            <label
                                htmlFor={`${formRegisterName}-${option.id}`}
                                className={`drinkcard-cc drinkcard-cc-trucks ${getClassNameForFuelTruckName(option.name)}`}
                            >
                                <img className={"img-fluid"} src={require(`../../assets/images/tankwagen/${getClassNameForFuelTruckName(option.name)}.png`)} />
                                {option.name}
                            </label>
                        </div>
                    ))
                }
            </div>
        </>
    )
};

export default FuelTruckForPriceFormPart;