import merge from "deepmerge";
import {createContext, FunctionComponent, PropsWithChildren, useContext, useEffect} from "react";

const WINDOW_KEY = 'rot-hel';

export type Config = {
    debug: boolean,
    apiBaseUrl: string,
    sepaPaymentMethodIds: string[],
    dateOfBirthRequiredPaymentMethodIds: string[],
    enableHubSpotIntegration: boolean,
    hubSpotPortalId: string,
    hubSpotFormId: string,
    enableContinuousSystemStatusCheck: boolean,
    continuousSystemStatusCheckEverySec: number,
}

export const paymentMethodIsSepa = (paymentMethodId: string) => getConfig().sepaPaymentMethodIds.includes(paymentMethodId);
export const paymentMethodNeedsDateOfBirth = (paymentMethodId: string) => getConfig().dateOfBirthRequiredPaymentMethodIds.includes(paymentMethodId);

const defaultConfig: Config = {
    apiBaseUrl: 'https://backend.heizoelrechner.roth-energie.de',
    debug: false,
    sepaPaymentMethodIds: ['4'],
    dateOfBirthRequiredPaymentMethodIds: ['2', '3', '4'],
    enableHubSpotIntegration: true,
    hubSpotPortalId: '8485739',
    hubSpotFormId: 'c107dafe-705a-47c2-961e-a85eda5ab43d',
    enableContinuousSystemStatusCheck: false,
    continuousSystemStatusCheckEverySec: 60,
}

export const withDefaultConfig = (config?: Config): Config => merge(defaultConfig, config ?? {});

const ConfigContext = createContext<Config>(defaultConfig)

const useConfig = () => useContext(ConfigContext)

// @ts-ignore
export const getConfig = (): Config => window[WINDOW_KEY]

type ProvideConfigProps = {
    config?: Config,
}

export const ProvideConfig: FunctionComponent<PropsWithChildren<ProvideConfigProps>> = ({ config = defaultConfig, children }) => {
    useEffect(() => {
        // @ts-ignore
        window[WINDOW_KEY] = config
        if (config.debug) console.log('rot-hel: config-loaded', config);
    }, [config]);

    return (
        <ConfigContext.Provider value={config}>
            {children}
        </ConfigContext.Provider>
    );
}

export default useConfig
