import {FunctionComponent, useEffect} from "react";
import {useFormContext} from "react-hook-form";
import {CheckoutFormData, CheckoutFormSalutation} from "./CheckoutForm";
import InlineFormError from "../InlineFormError";
import translate, {TranslationKey} from "../../utils/translation";

interface InvoiceAddressCheckoutFormPartProps {
    zipcode?: string,
}

const InvoiceAddressCheckoutFormPart: FunctionComponent<InvoiceAddressCheckoutFormPartProps> = (props) => {
    const { register, setValue, watch } = useFormContext<CheckoutFormData>();

    const salutationWatcher = watch('invoiceAddress.salutation');

    useEffect(() => {
        setValue(`invoiceAddress.salutation`, CheckoutFormSalutation.Herr);
        if (props.zipcode) setValue(`invoiceAddress.zipcode`, props.zipcode);
    }, []);

    return (
        <>
            <h4 className='mt-2'>Rechnungsadresse</h4>
            <div className='row'>
                <div className='col-2'>
                    <label>Anrede*</label>
                    <select {...register(`invoiceAddress.salutation`, { required: { value: true, message: translate(TranslationKey.RequiredFieldError) } })} className='form-select'>
                        {
                            Object.entries(CheckoutFormSalutation).map(([key, value]) => (
                                <option key={key}>{value}</option>
                            ))
                        }
                    </select>
                    <InlineFormError name={`invoiceAddress.salutation`}/>
                </div>

                <div className='col-5'>
                    <label>Vorname*</label>
                    <input {...register(`invoiceAddress.firstName`, { required: { value: true, message: translate(TranslationKey.RequiredFieldError) } })} type='text' className='form-control'/>
                    <InlineFormError name={`invoiceAddress.firstName`}/>
                </div>

                <div className='col-5'>
                    <label>Name*</label>
                    <input {...register(`invoiceAddress.lastName`, { required: { value: true, message: translate(TranslationKey.RequiredFieldError) } })} type='text' className='form-control'/>
                    <InlineFormError name={`invoiceAddress.lastName`}/>
                </div>

                {
                    salutationWatcher === CheckoutFormSalutation.Firma && (
                        <div className='row'>
                            <div className='col'>
                                <label>Firma*</label>
                                <input {...register(`invoiceAddress.company`, { required: { value: true, message: translate(TranslationKey.RequiredFieldError) } })} type='text' className='form-control'/>
                                <InlineFormError name={`invoiceAddress.company`}/>
                            </div>
                        </div>
                    )
                }
            </div>

            <div className={'row'}>
                <div className={'col-8'}>
                    <label>Straße*</label>
                    <input {...register(`invoiceAddress.street`, { required: { value: true, message: translate(TranslationKey.RequiredFieldError) } })} type='text' className='form-control'/>
                    <InlineFormError name={`invoiceAddress.street`}/>
                </div>
                <div className={'col-4'}>
                    <label>Hausnummer*</label>
                    <input {...register(`invoiceAddress.housenumber`, { required: { value: true, message: translate(TranslationKey.RequiredFieldError) } })} type='text' className='form-control'/>
                    <InlineFormError name={`invoiceAddress.housenumber`}/>
                </div>
            </div>

            <div className={'row'}>
                <div className={'col-4'}>
                    <label>Postleitzahl*</label>
                    <input {...register(`invoiceAddress.zipcode`, { required: { value: true, message: translate(TranslationKey.RequiredFieldError) } })} type='text' className='form-control'/>
                    <InlineFormError name={`invoiceAddress.zipcode`}/>
                </div>
                <div className={'col-8'}>
                    <label>Ort*</label>
                    <input {...register(`invoiceAddress.city`, { required: { value: true, message: translate(TranslationKey.RequiredFieldError) } })} type='text' className='form-control'/>
                    <InlineFormError name={`invoiceAddress.city`}/>
                </div>
            </div>

            <div className={'row'}>
                <div className={'col-md-12'}>
                    <label>Ortsteil</label>
                    <input {...register(`invoiceAddress.district`)} type='text' className='form-control'/>
                </div>
            </div>
        </>
    );
};

export default InvoiceAddressCheckoutFormPart;