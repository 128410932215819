import {FunctionComponent, PropsWithChildren} from "react";
import {Outlet} from "react-router-dom";
import useConfig from "../hooks/useConfig";

const Container: FunctionComponent<PropsWithChildren> = (props) => {
    const { debug, apiBaseUrl } = useConfig();

    return (
        <section>
            <div className='container'>
                <div className='hr'>
                    <Outlet/>
                </div>
            </div>
            { debug && <p>config.apiBaseUrl: {apiBaseUrl}</p> }
        </section>
    );
};

export default Container;