import React, { useEffect, useState } from 'react';
import { MemoryRouter, Route, Routes } from "react-router-dom";
import Main from "./views/Main";
import Loading from "./components/Loading";
import { ProvideStore } from "./hooks/useStore";
import Checkout from "./views/Checkout";
import CheckoutSucceeded from "./views/CheckoutSucceeded";
import Container from "./layouts/Container";
import ConfirmCheckout from "./views/ConfirmCheckout";
import CheckoutContainer from "./layouts/CheckoutContainer";
import DesiredPrice from "./views/DesiredPrice";
import DesiredPriceSucceeded from "./views/DesiredPriceSucceeded";
import useConfig from './hooks/useConfig';
import { SystemStatusResponse } from './utils/types';

function App() {
    const { apiBaseUrl, enableContinuousSystemStatusCheck, continuousSystemStatusCheckEverySec  } = useConfig()
    const [isLoading, setLoading] = useState(false)
    const [ isOnline, setOnline ] = useState(true)

    useEffect(() => {
        const abortController = new AbortController()
        const signal = abortController.signal
        let timeout: NodeJS.Timeout

        const handleSystemStatus = () => {
            fetch(`${apiBaseUrl}/system-status`, { signal })
              .then((res) => {
                  if (res.status !== 200) throw new Error(`Invalid status code, expected 200 got ${res.status}`);
                  return res.json();
              })
              .then((data: SystemStatusResponse) => {
                  setOnline(data.hel.online)
              })
              .finally(() => {
                  if (enableContinuousSystemStatusCheck) {
                      timeout = setTimeout(() => {
                          handleSystemStatus()
                      }, continuousSystemStatusCheckEverySec * 1000)
                  }
              })
        }

        handleSystemStatus()
        return () => {
            clearTimeout(timeout)
            abortController.abort()
        }
    })

    if (!isOnline) {
        return <i>Der Heizölrechner ist aktuell nicht erreichbar. Bitte versuchen Sie es später noch einmal.</i>
    }

    return (
        <div className="App">
            <ProvideStore>
                <Loading isLoading={isLoading}>
                    <MemoryRouter>
                        <Routes>
                            <Route element={<Container/>}>
                                <Route index element={<Main/>} />
                                <Route element={<CheckoutContainer/>}>
                                    <Route path='/checkout' element={<Checkout/>} />
                                    <Route path='/checkout-confirm' element={<ConfirmCheckout/>} />
                                    <Route path='/checkout-succeeded' element={<CheckoutSucceeded to="/checkout-succeeded"/>} />
                                    <Route path='/desired-price' element={<DesiredPrice/>} />
                                    <Route path='/desired-price-succeeded' element={<DesiredPriceSucceeded/>} />
                                </Route>
                            </Route>
                        </Routes>
                    </MemoryRouter>
                </Loading>
            </ProvideStore>
        </div>
    );
}

export default App;
