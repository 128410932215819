import {FunctionComponent} from "react";
import {useFormContext} from "react-hook-form";
import {CheckoutFormData} from "./CheckoutForm";
import InlineFormError, {WithErrorBorder} from "../InlineFormError";
import translate, {TranslationKey} from "../../utils/translation";
import {paymentMethodNeedsDateOfBirth} from "../../hooks/useConfig";

const PersonalCheckoutFormPart: FunctionComponent = () => {
    const { register, getValues } = useFormContext<CheckoutFormData>();

    return (
        <>
            <h4>Persönliche Informationen</h4>
            <label>E-Mail*</label>
            <input {...register('personal.email', { required: { value: true, message: translate(TranslationKey.RequiredFieldError) } })} type='email' className={WithErrorBorder('personal.email', 'form-control')}/>
            <InlineFormError name={'personal.email'}/>

            <div className='row'>
                <div className='col-2'>
                    <label>Vorwahl*</label>
                    <input {...register('personal.phonePrefix', { required: { value: true, message: translate(TranslationKey.RequiredFieldError) } })} type='text' className={WithErrorBorder('personal.phonePrefix', 'form-control')}/>
                    <InlineFormError name={'personal.phonePrefix'}/>
                </div>
                <div className='col text-center'>
                    <p className='mt-2'>/</p>
                </div>
                <div className='col-9'>
                    <label>Telefon*</label>
                    <input {...register('personal.phoneNumber', { required: { value: true, message: translate(TranslationKey.RequiredFieldError) } })} type='text' className={WithErrorBorder('personal.phoneNumber', 'form-control')}/>
                    <InlineFormError name={'personal.phoneNumber'}/>
                </div>
            </div>

            { paymentMethodNeedsDateOfBirth(getValues('deliveryOptions.paymentMethod')) && (
                <div className='row'>
                    <div className='col-2'>
                        <label>Geburtsdatums*</label>
                        <input {...register('personal.dateOfBirth', { required: { value: true, message: translate(TranslationKey.RequiredFieldError) } })} type='date' className={WithErrorBorder('personal.dateOfBirth', 'form-control')}/>
                        <InlineFormError name={'personal.dateOfBirth'}/>
                    </div>
                </div>
            )}
        </>
    );
};

export default PersonalCheckoutFormPart;