import {Dispatch, FunctionComponent, SetStateAction, useState} from "react";
import { ReactSVG } from "react-svg";
import {useStore} from "../hooks/useStore";
import {toPriceString} from "../utils/toPriceString";
import {Outlet} from "react-router-dom";

export type CheckoutContainerContext = {
    setTitle: Dispatch<SetStateAction<string>>,
    setSubtitle: Dispatch<SetStateAction<string>>,
};

const CheckoutContainer: FunctionComponent = () => {
    const { state } = useStore();
    const [title, setTitle] = useState('');
    const [subtitle, setSubtitle] = useState('');

    return (
        <>
            <h1>{title}</h1>
            <div className='card infobox'>
                <div className='row align-items-center justify-content-between'>
                    <div className='col-5'>
                        {state.product === 'premium' ?
                            // TODO reduce image or get svg
                            <img className={"img-fluid"} src={require('../assets/images/heizoel_premium_plus_pro_klima.jpg')} width={550} height={72}/>
                            :
                            <ReactSVG src={require('../assets/images/heizoel_plus.svg')}/>
                        }
                    </div>
                    <div className='col-6'>
                        <div className='row justify-content-end'>
                            <div className='col-sm-6 col-6'>
                                <p className='mb-0'><strong>Einzelpreis</strong></p>
                                <p className='mb-0'>
                                    <span className='preis'>{toPriceString(state.basePriceBrutto)}</span> / 100 Liter<br/>
                                    <span className="small">inkl. 19% MwSt ({toPriceString(state.basePriceNetto)} zzgl. MwSt)</span>
                                </p>
                            </div>
                            <div className="col-sm-6 col-4">
                                <div className="mt-1 mt-sm-0">
                                    <p className="mb-0"><strong>Gesamtpreis</strong></p>
                                    <p className="mb-0">
                                        <span className="preis">{toPriceString(state.fullPriceBrutto)}</span><br/>
                                        <span className="small">inkl. 19% MwSt</span>
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {
                subtitle
                    ? (<p>{subtitle}</p>)
                    : (
                        <p>
                            Ihr individuelles Heizöl-Preisangebot für die <strong>Postleitzahl {state.forPriceFormData?.zipcode}</strong> bei
                            Bestellung von <strong>{state.forPriceFormData?.overallDeliveryQuantityInLiter} Litern</strong> ({state.forPriceFormData?.amountOfDeliveryPoints} Lieferstelle).<br/>
                        </p>
                    )
            }

            <div className="row justify-content-between mt-2">
                <div className="col-7">
                    <Outlet context={{ setTitle, setSubtitle }}/>
                </div>

                <div className="col-4">
                    <div className="card active">
                        <div className="fill">
                            <img src={require('../assets/images/beratung-demo.jpg')} width="700" height="390" alt="Beratung"
                                 className="img-fluid"/>
                        </div>

                        <p className="large"><strong>Wir beraten Sie auch gerne telefonisch</strong></p>
                        <p className="preis mt-1">
                            <svg xmlns="http://www.w3.org/2000/svg" width="26" height="26"
                                 fill="currentColor" className="bi bi-telephone-fill" viewBox="0 0 16 16">
                                <path fillRule="evenodd"
                                      d="M1.885.511a1.745 1.745 0 0 1 2.61.163L6.29 2.98c.329.423.445.974.315 1.494l-.547 2.19a.678.678 0 0 0 .178.643l2.457 2.457a.678.678 0 0 0 .644.178l2.189-.547a1.745 1.745 0 0 1 1.494.315l2.306 1.794c.829.645.905 1.87.163 2.611l-1.034 1.034c-.74.74-1.846 1.065-2.877.702a18.634 18.634 0 0 1-7.01-4.42 18.634 18.634 0 0 1-4.42-7.009c-.362-1.03-.037-2.137.703-2.877L1.885.511z"/>
                            </svg>
                            { state.salesOfficePhoneNumber }
                        </p>
                    </div>
                </div>
            </div>
        </>
    );
}

export default CheckoutContainer;