import React from "react";
import {createRoot} from "react-dom/client";
import './assets/scss/app.scss';
import App from "./App";
import {Config, ProvideConfig, withDefaultConfig} from "./hooks/useConfig";

class RothHel {
    public run(rootSelector:string = '#root', styleSelector:string = '#hel-styles', config?: Config) {
        /*
        * - Create container div inside shadowDom
        *   and render react into container.
        * - move styling into shadowDom
        */

        if (document.querySelector(`${styleSelector}`) === null) {
            throw new Error(`No style tag with id ${styleSelector} found!`);
        }

        const tree = function (styles: JSX.Element): JSX.Element {
            return (
                <React.StrictMode>
                    <div className="roth-hel">
                        <ProvideConfig config={withDefaultConfig(config)}>
                            {styles}
                            <App />
                        </ProvideConfig>
                    </div>
                </React.StrictMode>
            );
        };

        const host = document.querySelector(rootSelector) as HTMLElement;

        if (host.shadowRoot == null || typeof host.shadowRoot === 'undefined') {
            // open shadowDom if not already initialized
            const shadowDom = host.attachShadow({mode: 'open'});
        }

        const container = document.createElement('div');
        const root = createRoot(container);
        // @ts-ignore
        host.shadowRoot.appendChild(container);


        // copy stylesheet
        const stylesheet = document.querySelector(`${styleSelector}`)?.cloneNode(true);
        document.querySelector(`${styleSelector}`)?.remove();
        // @ts-ignore
        const localStylesheet = <style id={`${styleSelector}`} >{stylesheet.innerHTML}</style>


        root.render(tree(localStylesheet));
    }
}

const rothHel = new RothHel();
export default rothHel;